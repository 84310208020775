import React, { FC, useState } from 'react'
import { Techmodel } from '../../store/modules'
import { Link } from 'react-router-dom'
import './SpecialCard.scss'
import ModalContact from '../../Pages/ModalContact/ModalContact'
import greyBg from '../../assets/Main_images/grey.jpg'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'

const SpecialCard: FC<Techmodel> = ({
	description_RU,
	description_CHS,
	id,
	name_RU,
	name_CHS,
	photo,
	price,
	year,
	type_name_CHS,
	type_name_RU,
	existence_CHS,
	existence_RU
}) => {
	const [enter, setEnter] = useState(false)
	const { t } = useTranslation()

	const lang = i18n.language
	const name = lang === 'RU' ? `${name_RU}` : `${name_CHS}`;
	const type_name = lang === 'RU' ? `${type_name_RU?.title_RU}` : `${type_name_CHS?.title_CHS}`;
	const description = lang === 'RU' ? `${description_RU}` : `${description_CHS}`;

	return (
		<Link to={`/detail-equipment/${id}`} className="SEquipmentRightCardItems">
			<div className='specialDetailImg'>
				<img width={440} height={335} src={!photo ? greyBg : photo} alt={`${name_RU}`} />
			</div>
			<div className="SECardTitle">
				<h2 className='name'>{name?.length > 15 ? name?.slice(0, 15) + '...' : name}</h2>
				<p className='description'>{description?.length > 15 ? description?.slice(0, 90) + '...' : description}</p>
				<h3 className='TitleTypeName'>{type_name}</h3>
				<p className='year'>{t("specialCard.year")} {year}</p>
				<h4 className='existence'>{lang === 'RU' ? existence_RU : existence_CHS}</h4>
				<div className='PriceCard'>
					<h3 className='price'>$ {price}</h3>
					<div className='linePrice'></div>
				</div>
				<button onClick={() => setEnter(!enter)} className='btnOrder'>{t('popularCard.btnorder')}</button>
			</div>
			{enter && <ModalContact enter={enter} setEnter={setEnter} />}
		</Link>

	);
};

export default SpecialCard;

import React, { FC, useEffect, useState } from 'react'
import s from './ServiceAuto.module.scss'
import ModalContact from '../ModalContact/ModalContact'
import 'animate.css'
import { Helmet } from 'react-helmet-async'
import { useAppSelector } from '../../store/hooks/hooks'
import { useTranslation } from 'react-i18next'

const ServiceAuto: FC = () => {
	const [enter, setEnter] = useState(false)
	const { info } = useAppSelector(state => state.spareParts)
	const { t, i18n } = useTranslation()
	const lang = i18n.language

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className={s.container}>
			<Helmet>
				<link rel='canonical' href='http://imotors.kg/services' />
				<title>{t('helmet.serviceAuto')}</title>
			</Helmet>
			<div className={s.wrapper}>
				<div className={s.content}>
					<img src={info?.renovationWorkImage} alt={`${info?.[`renovationWorkTitle_${lang}`]}`} />
					<div className={s.content_text}>
						<div className={s.content_title}>
							<h2>{info?.[`renovationWorkTitle_${lang}`]}</h2>
						</div>
						<p>{info?.[`renovationWorkDescription_${lang}`]}</p>
						<button onClick={() => setEnter(!enter)}>{t("services.contact")}</button>
					</div>
				</div>
				<div className={s.content}>
					<img src={info?.serviceStationImage} alt={`${info?.[`serviceStationTitle_${lang}`]}`} />
					<div className={s.content_text}>
						<div className={s.content_title}>
							<h2>{info?.[`serviceStationTitle_${lang}`]}</h2>
						</div>
						<p>{info?.[`serviceStationDescription_${lang}`]}</p>
						<button onClick={() => setEnter(!enter)}>{t("services.contact")}</button>
					</div>
				</div>
				<div className={s.content}>
					<img src={info?.shippingImage} alt={`${info?.[`shippingTitle_${lang}`]}`} />
					<div className={s.content_text}>
						<div className={s.content_title}>
							<h2>{info?.[`shippingTitle_${lang}`]}</h2>
						</div>
						<p>{info?.[`shippingDescription_${lang}`]}</p>
						<button onClick={() => setEnter(!enter)}>{t("services.contact")}</button>
					</div>
				</div>
			</div>
			{enter && <ModalContact enter={enter} setEnter={setEnter} />}
		</div>
	)
}

export default ServiceAuto

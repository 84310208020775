import React, { FC, useEffect } from 'react'
import s from './AboutUs.module.scss'
import { Helmet } from 'react-helmet-async'
import { useAppSelector } from '../../store/hooks/hooks'
import { useTranslation } from 'react-i18next'

const AboutUs: FC = () => {
	const { info } = useAppSelector(state => state.spareParts)

	const { i18n, t } = useTranslation()

	const lang = i18n.language

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div className={s.container}>
			<Helmet>
				<link rel='canonical' href={`http://imotors.kg/about-us`} />
				<title>{t('helmet.aboutUs')}</title>
			</Helmet>
			<div className={s.wrapper}>
				<div className={s.main_content}>
					<h1>{info?.[`aboutTitle_${lang}`]}</h1>
					<p>{info?.[`aboutDescription_${lang}`]}</p>
				</div>
				<div className={s.block_content}>
					<div className={s.tor_block}>
						<img
							className={s.transport}
							src={info?.aboutImageOne}
							alt='Transport'
						/>
						<div className={s.text_wrapper}>
							<div className={s.block_text}>
								<h2>{info?.[`aboutBlocOneTitle_${lang}`]}</h2>
								<p>{info?.[`aboutBlocOneDescription_${lang}`]}</p>
							</div>
						</div>
					</div>
					<div className={s.bottom_block}>
						<div className={s.text_wrapper}>
							<div className={s.block_text}>
								<h2>{info?.[`aboutBlocTwoTitle_${lang}`]}</h2>
								<p>{info?.[`aboutBlocTwoDescription_${lang}`]}</p>
							</div>
						</div>
						<img
							className={s.transport}
							src={info?.aboutImageTwo}
							alt='Transport'
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutUs

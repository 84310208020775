import React, { FC, useState } from 'react'
import s from './SparePartsCard.module.scss'
import { ISparePart } from '../../store/modules'
import { Link } from 'react-router-dom'
import ModalContact from '../ModalContact/ModalContact'
import empty from '../../assets/SparePartCard_images/fon-tekstura-matovaia-seryi.jpg'
import { useTranslation } from 'react-i18next'

const SparePartsCard: FC<ISparePart> = ({
	description_CHS,
	description_RU,
	photo,
	id,
	price,
	title_CHS,
	title_RU,
	year,
	existence_CHS,
	existence_RU,
}) => {
	const [enter, setEnter] = useState(false)
	const { t, i18n } = useTranslation()
	const lang = i18n.language
	const title = lang === 'RU' ? title_RU : title_CHS
	const existance = lang === 'RU' ? existence_RU : existence_CHS
	const description = lang === 'RU' ? description_RU : description_CHS

	return (
		<div className={s.content}>
			<div className={s.image}>
				<Link to={`/detail-spare-parts/${title}?spare=${id}`}>
					<img
						width={440}
						height={335}
						src={!photo ? empty : photo}
						alt={title}
					/>
				</Link>
			</div>
			<div className={s.content_text}>
				<Link to={`/detail-spare-parts/${title}?spare=${id}`}>
					<div className={s.content_block_top}>
						<h2>{title}</h2>
						<p className={s.description}>
							{description?.length > 15
								? description.slice(0, 100) + '...'
								: description}
						</p>
						<p className={s.year}>
							{t('sparePartCard.year')} {year}
						</p>
					</div>
					<div className={s.content_block_bottom}>
						<h3 className={s.instock}>{existance}</h3>
						<h3 className={s.price}>
							{price} {lang === 'RU' ? 'сом' : 'KGS'}
						</h3>
						<button onClick={() => setEnter(!enter)} className={s.service_btn}>
							{t('sparePartCard.order')}
						</button>
					</div>
				</Link>
			</div>
			{enter && (
				<ModalContact
					// title={title}
					enter={enter}
					setEnter={setEnter}
				/>
			)}
		</div>
	)
}

export default SparePartsCard

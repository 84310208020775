import React, { FC, useEffect, useState } from 'react';
import './SettingBtn.scss'
import { fetchByAllTechmodel, fetchBySearch } from '../../store/slice/specialEquipmentSlice';
import { useAppDispatch } from '../../store/hooks/hooks';
import filtrSearch from '../../assets/Main_images/VectorfilterSearchIcon.png'
import { GrClose } from 'react-icons/gr';
import Acor from '../AcorCop/AcorCop';
import { useTranslation } from 'react-i18next';


interface SettingFilterProps {
    setFilterBurger: (e: boolean) => void
    filterBurger: boolean
}

const SettingBtn: FC<SettingFilterProps> = ({ filterBurger, setFilterBurger }) => {
    const [value, setValue] = useState('')
    const dispatch = useAppDispatch()
    const { t } = useTranslation()


    useEffect(() => {
        if (value.trim().length) {
            dispatch(fetchBySearch(value))
        } else {
            dispatch(fetchByAllTechmodel())
        }
    }, [value, dispatch])

    const hideModal = () => {
        setFilterBurger(false)
    }

    useEffect(() => {
        // При рождении убрать скрол
        // document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', e => {
            e.code === 'Escape' && hideModal()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (filterBurger) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => setFilterBurger(false)
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!filterBurger) window.history.back()
            window.onpopstate = () => { }
        }
    }, [dispatch])


    return (

        <div onClick={hideModal} className='SettingBurgerMenu'>
            <div onClick={e => e.stopPropagation()} className='SettingBox'>
                <div className='Sclose_block'>
                    <GrClose onClick={hideModal} className='Sclose_icon' />
                </div>
                <div className='AcorSetting'>
                    <Acor />
                </div>
                <input value={value} onChange={(e) => setValue(e.target.value)} placeholder={t('header.search')} className='searchButtton' type="search" />
                <img className='filtrSearch' src={filtrSearch} alt="search" />
            </div>


        </div>
    );
};

export default SettingBtn;
import React, { FC } from 'react';
import './EquipmentYearFilter.scss';
import { useTranslation } from 'react-i18next';
// import { fetchByYear } from '../../../store/slice/specialEquipmentSlice';

interface EquipmentYearFilterProps {
    year_min: string;
    year_max: string;
    onChange: (key: string, value: string) => void;
}

const EquipmentYearFilter: FC<EquipmentYearFilterProps> = ({ onChange, year_max, year_min }) => {
    const { t } = useTranslation()

    return (
        <div className='YearFilter'>
            <label className='YearLabel'>
                {t('specialAcor.from')}
                <input
                    value={year_min}
                    onChange={(e) => onChange('year_min', e.target.value)}
                    className='YearInput'
                    type="text"
                />
            </label>
            <label className='YearLabel'>
                {t('specialAcor.before')}
                <input
                    value={year_max}
                    onChange={(e) => onChange('year_max', e.target.value)}
                    className='YearInput'
                    type="text"
                />
            </label>
        </div>
    );
};

export default EquipmentYearFilter;

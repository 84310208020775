import React, { FC, useState, FormEventHandler } from 'react'
import s from './FormContact.module.scss'
import { IOrder } from '../../store/modules'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks'
import { fetchByAddNewOrder } from '../../store/slice/sparePartsSlice'
import ModalSuccess from './ModalSuccess/ModalSuccess'
import ContactLoading from '../Loading/ContactLoading/ContactLoading'
import { useTranslation } from 'react-i18next'

const FormContact: FC = () => {
	const dispatch = useAppDispatch()
	const { loading, modal_success } = useAppSelector(state => state.spareParts)
	const [orderData, setOrderData] = useState<IOrder>({
		client_name: '',
		description: '',
		email: '',
		telegram_login: '',
		whatsapp_number: '',
		phone_number: '',
	})
	const [errorText, setErrorText] = useState('')
	const { t } = useTranslation()

	const handleChange = (key: string, value: string) => {
		setOrderData({ ...orderData, [key]: value })
		validateForm()
	}

	const validateForm = () => {
		if (!orderData.client_name) {
			setErrorText(t('form.nameError'))
		} else if (!orderData.email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
			setErrorText(t('form.emailError'))
		} else if (!orderData.whatsapp_number) {
			setErrorText(t('form.phoneNumberWhatsappError'))
		} else {
			setErrorText('')
		}
	}

	const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault()
		if (
			!orderData.client_name ||
			!orderData.email ||
			!orderData.whatsapp_number
		) {
			setErrorText(t('form.fillField'))
			return
		}
		if (!orderData.email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
			setErrorText(t('form.emailError'))
			return
		}

		validateForm()

		await dispatch(fetchByAddNewOrder(orderData))
		setOrderData({
			client_name: '',
			description: '',
			email: '',
			telegram_login: '',
			whatsapp_number: '',
			phone_number: '',
		})
		setErrorText('')
	}

	if (loading) {
		return <ContactLoading />
	}

	return (
		<div className={s.wrapper}>
			<form onSubmit={handleSubmit}>
				<h2 className={s.title}>{t('form.title')}</h2>
				<div className={s.input_block}>
					<h3>{t('form.name')}</h3>
					<input
						onChange={e => handleChange('client_name', e.target.value)}
						value={orderData.client_name}
						placeholder={t('form.namePlaceholder')}
						type='text'
					/>
					{errorText === t('form.nameError') && (
						<p className={s.error}>{t('form.nameError')}</p>
					)}
				</div>
				<div className={s.input_block}>
					<h3>{t('form.email')}</h3>
					<input
						onChange={e => handleChange('email', e.target.value)}
						value={orderData.email}
						placeholder={t('form.emailPlaceholder')}
						type='email'
					/>
					{errorText === t('form.emailError') && (
						<p className={s.error}>{t('form.emailError')}</p>
					)}
				</div>
				<div className={s.input_block}>
					<h3>{t('form.phoneNumberWhatsapp')}</h3>
					<input
						onChange={e => handleChange('whatsapp_number', e.target.value)}
						value={orderData.whatsapp_number}
						placeholder={t('form.phoneNumberWhatsappPlaceholder')}
						type='text'
					/>
					{errorText === t('form.phoneNumberWhatsappError') && (
						<p className={s.error}>{t('form.phoneNumberWhatsappError')}</p>
					)}
				</div>
				<div className={s.input_block}>
					<h3>{t('form.phoneNumber')}</h3>
					<h2>{t('form.phoneNumberNecessarily')}</h2>
					<input
						onChange={e => handleChange('phone_number', e.target.value)}
						value={orderData.phone_number}
						placeholder='+996XXXXXXXXX'
						type='text'
					/>
				</div>
				<div className={s.input_block}>
					<h3>{t('form.telegram')}</h3>
					<input
						onChange={e => handleChange('telegram_login', e.target.value)}
						value={orderData.telegram_login}
						placeholder={t('form.telegramPlaceholder')}
						type='text'
					/>
				</div>
				<div className={s.input_block}>
					<h3>{t('form.describe')}</h3>
					<textarea
						onChange={e => handleChange('description', e.target.value)}
						value={orderData.description}
						rows={4}
						cols={50}
						placeholder={t('form.describePlaceholder')}
						className={s.description}
					></textarea>
				</div>
				<div className={s.btn}>
					<button>{t('form.send')}</button>
				</div>
				{errorText && <p className={s.error_s}>{errorText}</p>}
				{modal_success && <ModalSuccess />}
			</form>
		</div>
	)
}

export default FormContact

export const pathLink = 'https://imotors.kg/api/'


export const checkLinkToHTTPS = (link: string) => {
    if (link.startsWith('http')) {
        return `https${link.slice(4)}`
    } else if (link.startsWith('/media')) {
        return pathLink + link
    } else {
        return link
    }
}
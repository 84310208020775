import React, { FC, useState, useEffect } from 'react'
import s from './DetailSpareParts.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { fetchByDetailSparePart } from '../../store/slice/detailSpareParts'
import Loading from '../Loading/Loading'
import ModalContact from '../ModalContact/ModalContact'
import MySwiper from './Swiper/Swiper'
import DWhatsappImg from '../../assets/Footer_images/Vector4kwhatsapp.png'
import DTelegramImg from '../../assets/Footer_images/Vector4ktelegram.png'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

const DetailSpareParts: FC = () => {
	const { name } = useParams()
	const dispatch = useAppDispatch()
	const [searchParams] = useSearchParams()
	const [query] = useState(searchParams.get('spare'))
	const { detail, error, loading } = useAppSelector(state => state.details)
	const { infoFooter } = useAppSelector(state => state.special)

	useEffect(() => {
		query && dispatch(fetchByDetailSparePart(query))
	}, [dispatch, query])

	//=====================================
	const [enter, setEnter] = useState(false)

	//=====================================
	const [activeTab, setActiveTab] = useState<string>('description')

	//====================================

	const { t, i18n } = useTranslation()
	const lang = i18n.language
	return (
		<div className={s.container}>
			<Helmet>
				<link
					rel='canonical'
					href={`http://imotors.kg/detail-spare-parts/${name}/${query}`}
				/>
				<title>{name} | Айтамга Company</title>
			</Helmet>
			{loading ? (
				<Loading />
			) : error ? (
				<span className={s.error}>{error}</span>
			) : (
				<>
					<div className={s.wrapper}>
						<div className={s.block_media}>
							<MySwiper detail={detail} />
						</div>
						<div className={s.block_text}>
							<div className={s.top_media_text}>
								<h2>{detail?.[`title_${lang}`]}</h2>
								<h3>
									{detail?.price} {lang === 'RU' ? 'сом' : 'KGS'} <div className={s.line}></div>
								</h3>
								<div className={s.block_nav}>
									<button
										onClick={() => setActiveTab('description')}
										style={{
											backgroundColor:
												activeTab === 'description'
													? 'rgb(255, 205, 18)'
													: 'white',
										}}
									>
										{t('detailSparePart.description')}
									</button>
									<button
										onClick={() => setActiveTab('characteristics')}
										style={{
											backgroundColor:
												activeTab === 'characteristics'
													? 'rgb(255, 205, 18)'
													: 'white',
										}}
									>
										{t('detailSparePart.characteristics')}
									</button>
									<button
										onClick={() => setActiveTab('additional')}
										style={{
											backgroundColor:
												activeTab === 'additional'
													? 'rgb(255, 205, 18)'
													: 'white',
										}}
									>
										{t('detailSparePart.additional')}
									</button>
								</div>
							</div>

							<div className={s.top_bottom_text}>
								{activeTab === 'description' && (
									<p className={s.detail_texts}>{detail?.[`description_${lang}`]}</p>
								)}
								{activeTab === 'characteristics' && (
									<p className={s.detail_texts}>{detail?.[`characteristic_${lang}`]}</p>
								)}
								{activeTab === 'additional' && (
									<p className={s.detail_texts}>{detail?.[`additional_${lang}`]}</p>
								)}
								<div className={s.btn}>
									<button onClick={() => setEnter(!enter)}>
										{t('detailSparePart.order')}
									</button>
									<Link
										to={
											'https://api.whatsapp.com/send?phone=' +
											infoFooter?.whatsApp_RU
										}
									>
										<img
											src={DWhatsappImg}
											alt='what'
											className={s.icons_network}
										/>
									</Link>

									<Link to={'https://t.me/' + infoFooter?.telegram}>
										<img
											src={DTelegramImg}
											alt='tel'
											className={s.icons_network}
										/>
									</Link>
								</div>
							</div>
						</div>
						{enter && (
							<ModalContact
								enter={enter}
								setEnter={setEnter}
							// title={detail?.title}
							/>
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default DetailSpareParts

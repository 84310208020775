import React, { FC } from 'react'
import b from './Application.module.scss'
import { useAppSelector } from '../../../store/hooks/hooks'
import FormContact from '../../../Pages/FormContact/FormContact'

const Application: FC = () => {
	const { info } = useAppSelector(state => state.spareParts)

	return (
		<div className={b.container}>
			<div className={b.Application}>
				<div className={b.ApplicationBox}>
					<div className={b.ApplicationLeftCard}>
						<FormContact />
					</div>

					<div className={b.ApplicationRightCard}>
						<img src={info?.mainPageFormImage} alt='icon' />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Application

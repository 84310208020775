import React, { FC, useEffect, useState, FormEventHandler } from 'react'
import s from './ModalContact.module.scss'
import { IOrder } from '../../store/modules'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks'
import { fetchByAddNewOrder } from '../../store/slice/sparePartsSlice'
import { MdClose } from 'react-icons/md'
import ModalSuccess from '../FormContact/ModalSuccess/ModalSuccess'
import ContactLoading from '../Loading/ContactLoading/ContactLoading'
import { useTranslation } from 'react-i18next'

interface FormProps {
	setEnter: (e: boolean) => void
	enter: boolean
	// title: string | undefined
}

const ModalContact: FC<FormProps> = ({ enter, setEnter }) => {
	const dispatch = useAppDispatch()
	const { loading } = useAppSelector(state => state.spareParts)
	const [orderData, setOrderData] = useState<IOrder>({
		client_name: '',
		description: '',
		email: '',
		telegram_login: '',
		whatsapp_number: '',
		phone_number: '',
	})
	const [errorText, setErrorText] = useState('')

	const handleChange = (key: string, value: string) => {
		setOrderData({ ...orderData, [key]: value })
		validateForm()
	}

	const { t } = useTranslation()

	const validateForm = () => {
		if (!orderData.client_name) {
			setErrorText(t('form.nameError'))
		} else if (!orderData.email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
			setErrorText(t('form.emailError'))
		} else if (!orderData.whatsapp_number) {
			setErrorText(t('form.phoneNumberWhatsappError'))
		} else {
			setErrorText('')
		}
	}

	const { modal_success } = useAppSelector(state => state.spareParts)

	const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
		e.preventDefault()
		if (
			!orderData.client_name ||
			!orderData.email ||
			!orderData.whatsapp_number
		) {
			setErrorText(t('form.fillField'))
			return
		}
		if (!orderData.email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
			setErrorText(t('form.emailError'))
			return
		}
		await dispatch(fetchByAddNewOrder(orderData))
		console.log(orderData)
		setOrderData({
			client_name: '',
			description: '',
			email: '',
			telegram_login: '',
			whatsapp_number: '',
			phone_number: '',
		})
		setErrorText('')
		setModalEnter(true)
	}

	//========================================
	const hideModal = () => {
		setEnter(false)
	}

	useEffect(() => {
		// При рождении убрать скрол
		document.body.style.overflow = 'hidden'
		// При нажатии на ESC закрыть модальное окно
		document.addEventListener('keydown', e => {
			e.code === 'Escape' && hideModal()
		})
		// При рождении навесит другое событие на кнопку назад у браузера
		if (enter) {
			window.history.pushState(null, '', window.location.href)
			window.onpopstate = () => setEnter(false)
		}
		return () => {
			// При закрытии  модального окна вернуть скролл
			document.body.style.overflow = 'auto'
			// При закрытии убрать действия с кнопки ESC
			document.removeEventListener('keydown', () => { })
			// При закрытии вернуть действие по умолчанию на кнопку назад в браузере
			if (!enter) window.history.back()
			window.onpopstate = () => { }
		}
	}, [])

	const handleCloseIconClick = () => {
		hideModal()
	}

	const [modalEnter, setModalEnter] = useState(false)

	if (loading) {
		return <ContactLoading />
	}

	return (
		<div onClick={hideModal} className={s.wrapper}>
			<div className={s.container} onClick={e => e.stopPropagation()}>
				<form onSubmit={handleSubmit}>
					<div className={s.form_header}>
						<h2 className={s.title}>{t('form.title')}</h2>
						<MdClose onClick={handleCloseIconClick} className={s.close_icon} />
					</div>
					<div className={s.input_block}>
						<h3>{t('form.name')}</h3>
						<input
							onChange={e => handleChange('client_name', e.target.value)}
							value={orderData.client_name}
							placeholder={t('form.namePlaceholder')}
							type='text'
						/>
						{errorText === t('form.nameError') && (
							<p className={s.error}>{t('form.nameError')}</p>
						)}
					</div>
					<div className={s.input_block}>
						<h3>{t('form.email')}</h3>
						<input
							onChange={e => handleChange('email', e.target.value)}
							value={orderData.email}
							placeholder={t('form.emailPlaceholder')}
							type='email'
						/>
						{errorText === t('form.emailError') && (
							<p className={s.error}>{t('form.emailError')}</p>
						)}
					</div>
					<div className={s.input_block}>
						<h3>{t('form.phoneNumberWhatsapp')}</h3>
						<input
							onChange={e => handleChange('whatsapp_number', e.target.value)}
							value={orderData.whatsapp_number}
							placeholder={t('form.phoneNumberWhatsappPlaceholder')}
							type='text'
						/>
						{errorText === t('form.phoneNumberWhatsappError') && (
							<p className={s.error}>{t('form.phoneNumberWhatsappError')}</p>
						)}
					</div>
					<div className={s.input_block}>
						<h3>{t('form.phoneNumber')}</h3>
						<h2>{t('form.phoneNumberNecessarily')}</h2>
						<input
							onChange={e => handleChange('phone_number', e.target.value)}
							value={orderData.phone_number}
							placeholder='+996XXXXXXXXX'
							type='text'
						/>
					</div>
					<div className={s.input_block}>
						<h3>{t('form.telegram')}</h3>
						<input
							onChange={e => handleChange('telegram_login', e.target.value)}
							value={orderData.telegram_login}
							placeholder={t('form.telegramPlaceholder')}
							type='text'
						/>
					</div>
					<div className={s.btn}>
						<button>{t('form.send')}</button>
					</div>
					{errorText && <p className={s.error_s}>{errorText}</p>}
					{modal_success && <ModalSuccess />}
				</form>
			</div>
		</div>
	)
}

export default ModalContact

import React, { FC, useEffect } from 'react'
import s from './ModalSuccess.module.scss'
import { IoCheckmarkDoneCircle } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { toggleModal } from '../../../store/slice/sparePartsSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks'

const ModalSuccess: FC = () => {
	const { modal_success } = useAppSelector(state => state.spareParts)
	const dispatch = useAppDispatch()
	const hideModal = () => {
		dispatch(toggleModal(false))
	}

	useEffect(() => {
		// При рождении убрать скрол
		document.body.style.overflow = 'hidden'
		// При нажатии на ESC закрыть модальное окно
		document.addEventListener('keydown', e => {
			e.code === 'Escape' && hideModal()
		})
		// При рождении навесит другое событие на кнопку назад у браузера
		if (modal_success) {
			window.history.pushState(null, '', window.location.href)
			window.onpopstate = () => hideModal()
		}
		return () => {
			// При закрытии  модального окна вернуть скролл
			document.body.style.overflow = 'auto'
			// При закрытии убрать действия с кнопки ESC
			document.removeEventListener('keydown', () => {})
			// При закрытии вернуть действие по умолчанию на кнопку назад в браузере
			if (!modal_success) window.history.back()
			window.onpopstate = () => {}
		}
	}, [dispatch])

	const { t } = useTranslation()
	return (
		<div onClick={hideModal} className={s.wrapper}>
			<div className={s.container} onClick={e => e.stopPropagation()}>
				<IoCheckmarkDoneCircle className={s.icon} />
				<h1>{t('modalSucces.title')}</h1>
			</div>
		</div>
	)
}

export default ModalSuccess

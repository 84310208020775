import React, { FC, useEffect, useState } from 'react'
import b from './DetailEquipment.module.scss'
import 'animate.css'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks'
import { Link, useParams } from 'react-router-dom'
import { fetchByDetailTechmodel } from '../../store/slice/specialDetailSlice'
import Loading from '../Loading/Loading'
import ModalContact from '../ModalContact/ModalContact'
import SwiperEquipment from './SwiperEquipment/SwiperEquipment'
import DetailWhatIcon from '../../assets/Main_images/whatsappdetailWhatsappIcon.png'
import DetailTeleIcon from '../../assets/Main_images/VectordetailtelegramIcon.png'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'

const DetailEquipment: FC = () => {
	const { t } = useTranslation()
	// const [img, setImg] = useState(false)
	const dispatch = useAppDispatch()
	const { id } = useParams()
	const { loading, specialDetail } = useAppSelector(
		state => state.specialDetail
	)

	const { infoFooter } = useAppSelector(state => state.special)
	const [enter, setEnter] = useState(false)
	const [activeTab, setActiveTab] = useState<
		'description' | 'characteristics' | 'additional'
	>('description')

	// console.log(specialDetail);

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		if (id) {
			dispatch(fetchByDetailTechmodel(Number(id)))
		}
	}, [dispatch, id])
	if (loading) {
		return (
			<h1>
				<Loading />
			</h1>
		)
	}
	const lang = i18n.language


	return (
		<div className={b.DetailEquipment}>
			<Helmet>
				<link
					rel='canonical'
					href={`http://imotors.kg/detail-spare-parts/${id}}`}
				/>
				<title>{`${specialDetail && specialDetail[`name_${lang}`]}`} | Айтамга Company</title>
			</Helmet>
			<div className={b.container}>
				<div className={b.DetailEquipmentBox}>
					<div className={b.DetailEquipmentLeftCard}>
						<SwiperEquipment specialDetail={specialDetail} />
					</div>

					<div className={b.DetailEquipmentRightCard}>
						<h2 className={b.DetailName}>{specialDetail?.[`name_${lang}`]}</h2>
						<h4 className={b.TypeName}>{lang === 'RU' ? specialDetail?.type_name_RU.title_RU : specialDetail?.type_name_CHS.title_CHS}</h4>
						<div className={b.Price}>
							<h3 className={b.DetailPrice}>$ {specialDetail?.price}</h3>
							<div className={b.PriceLine}></div>
						</div>
						<div className={b.DetailBtn}>
							<button
								className={b.detailbtnitem}
								style={{
									backgroundColor:
										activeTab === 'description' ? 'rgb(255, 205, 18)' : 'white',
								}}
								onClick={() => setActiveTab('description')}
							>
								{t('detailSparePart.description')}
							</button>
							<button
								className={b.detailbtnitem}
								onClick={() => setActiveTab('characteristics')}
								style={{
									backgroundColor:
										activeTab === 'characteristics'
											? 'rgb(255, 205, 18)'
											: 'white',
								}}
							>
								{t('detailSparePart.characteristics')}
							</button>
							<button
								className={b.detailbtnitem}
								onClick={() => setActiveTab('additional')}
								style={{
									backgroundColor:
										activeTab === 'additional' ? 'rgb(255, 205, 18)' : 'white',
								}}
							>
								{t('detailSparePart.additional')}
							</button>
						</div>
						{activeTab === 'description' && (
							<p className={b.DetailDescription}>
								{lang === 'RU' ? specialDetail?.description_RU : specialDetail?.description_CHS}
							</p>
						)}
						{activeTab === 'characteristics' && (
							<p className={b.DetailDescription}>
								{lang === 'RU' ? specialDetail?.characteristic_RU : specialDetail?.characteristic_CHS}
							</p>
						)}
						{activeTab === 'additional' && (
							<p className={b.DetailDescription}>{lang === 'RU' ? specialDetail?.additional_RU : specialDetail?.additional_CHS}</p>
						)}

						<div className={b.detailApBkBtn}>
							<button
								onClick={() => setEnter(!enter)}
								className={b.ApplicationBtn}
							>
								{t('popularCard.btnorder')}
							</button>

							<div className={b.detailIcon}>
								<Link
									to={
										'https://api.whatsapp.com/send?phone=' +
										infoFooter?.whatsApp_RU
									}
								>
									<img width={44} src={DetailWhatIcon} alt='wha' />
								</Link>
								<Link to={'https://t.me/' + infoFooter?.telegram}>
									<img src={DetailTeleIcon} alt='telegram' />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{enter && <ModalContact enter={enter} setEnter={setEnter} />}
		</div>
	)
}

export default DetailEquipment

import React, { FC, useEffect } from 'react'
import b from './Home.module.scss'
import 'animate.css'
import BgHome from '../../components/Section/BgHome/BgHome'
import Advantages from '../../components/Section/Advantages/Advantages'
import PopularTechnique from '../../components/Section/PopularTechnique/PopularTechnique'
import Services from '../../components/Section/Services/Services'
import Stamps from '../../components/Section/Stamps/Stamps'
import Application from '../../components/Section/Application/Application'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

const Home: FC = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const { t } = useTranslation()
	return (
		<div className={b.home}>
			<Helmet>
				<link rel='canonical' href='http://imotors.kg' />
				<title>{t('helmet.home')}</title>
			</Helmet>
			<BgHome />
			<div className={b.container}>
				<Advantages />
				<PopularTechnique />
				<Services />
			</div>
			<Stamps />
			<Application />
		</div>
	)
}

export default Home

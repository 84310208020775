import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import ruNs1 from './locales/RU/translation.json'
import chNs1 from './locales/CHS/translation.json'

export const defaultNS = 'translation';

const resources = {
    RU: {
        translation: ruNs1
    },
    CHS: {
        translation: chNs1
    }
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: 'RU',
        defaultNS,
        interpolation: {
            escapeValue: false,
        },
        resources,
        detection: {
            order: ["localStorage", "cookie"],
            caches: ["localStorage", "cookie"],
        },
    });

export default i18n;
import React, { FC } from 'react';
import greyBg from '../../../assets/Main_images/grey.jpg'
import b from './MobileSearch.module.scss'
import SearchMobil from '../../../assets/Header_images/VectorsearchIcon.png'
import { useAppSelector } from '../../../store/hooks/hooks';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
interface MobileSearchProps {
    setMobileSearch: (e: boolean) => void
    mobileSearch: boolean
    setSearch: (e: string) => void
    search: string

}
const MobileSearch: FC<MobileSearchProps> = ({ mobileSearch, setMobileSearch, search, setSearch }) => {
    const { special } = useAppSelector(state => state.special)
    const { t, i18n } = useTranslation()
    const hadleVisi = () => {
        setSearch('')
        setMobileSearch(false)
    }
    const lang = i18n.language


    return (

        <div className={b.MobileSearchBox}>
            <img onClick={() => setMobileSearch(!mobileSearch)} src={SearchMobil} className={b.SearchMobil} alt="logo" />
            {
                mobileSearch && search.trim().length >= 3 && <div className={b.searchMobileBox}>
                    {
                        special.length > 0 ?
                            special.slice(0, 3).map(el => (
                                <Link onClick={hadleVisi} to={`/detail-equipment/${el.id}`} className={b.searchCardMobile} key={el.id}>
                                    <img src={el.photo ? el.photo : greyBg} alt="logo" className={b.searchImg} />
                                    <div className={b.searchCardRigth}>
                                        <h2 className='searchName'>{lang === 'RU' ? el.name_RU?.length > 15 ? el.name_RU?.slice(0, 15) + '...' : el.name_RU : el.name_CHS?.length > 15 ? el.name_CHS?.slice(0, 15) + '...' : el.name_CHS}</h2>
                                        <h4 className='searchExistence'>{lang === 'RU' ? el?.existence_RU : el?.existence_CHS}</h4>
                                        <h3 className='searchPrice'>{el?.price}</h3>
                                    </div>
                                </Link>
                            ))
                            : <h2 className={b.searchNotFound}>{t('header.searchNotFound')}</h2>
                    }

                    <Link onClick={hadleVisi} to={`/special-equipment?search=${search}`}>
                        <h3 className={b.Viewmore}>{t('header.viewmore')}</h3></Link>
                </div>
            }

        </div>
    );
};

export default MobileSearch;
import React, { useEffect, useState } from 'react';
import './Header.scss';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import mapIcon from '../../assets/Header_images/addressblackMap.png'
import phoneIcon from '../../assets/Header_images/mdi_phonephoneBlack.png'
import whatsappIcon from '../../assets/Header_images/Vector4kwhatsapp.png'
import telegramIcon from '../../assets/Header_images/Vector4ktelegram.png'
import searchIcon from '../../assets/Header_images/VectorsearchIcon.png'
import instaIcon from '../../assets/Header_images/ri_instagram-fill4kinstagram.png'
import facebookIcon from '../../assets/Header_images/ic_baseline-facebook4kFacebook.png'
import Logo from '../../assets/Header_images/ЛОГО ГОРИЗОНТАЛЬНЫЙ-01 1AitamgaLogo.png'
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchBySearch } from '../../store/slice/specialEquipmentSlice';
import greyBg from '../../assets/Main_images/grey.jpg'
import MobileSearch from './MobileSearch/MobileSearch';
import { useTranslation } from 'react-i18next';

const Navbar: React.FC = () => {
	const { t, i18n } = useTranslation()
	const [isActiveBurger, setIsActiveBurger] = useState<boolean>(false);
	const [search, setSearch] = useState('')
	const [mobileSearch, setMobileSearch] = useState(false)
	const dispatch = useAppDispatch()
	const { special, infoFooter } = useAppSelector(state => state.special)
	const navToggle = (): void => {
		setIsActiveBurger(!isActiveBurger);
		setMobileSearch(false)
	};

	const handleSearch = () => {
		if (search.trim().length) {
			dispatch(fetchBySearch(search));
		}
	};

	const navigate = useNavigate()

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault(); // Предотвращаем стандартное поведение Enter, чтобы избежать отправки формы (если таковая имеется)
			handleSearch();
			setSearch('')
			navigate(`/special-equipment?search=${search}`)
		}
	};

	useEffect(() => {
		search.length >= 3 && dispatch(fetchBySearch(search))
	}, [dispatch, search]);

	const changeLanguage = (lang: string) => {
		i18n.changeLanguage(lang);
	};

	const lang = i18n.language



	return (
		<header>
			<div className='headerTopBox'>
				<div className='headerTop'>
					<div className='headerTopItems'>
						<img className='headerTopItemsIcons' src={mapIcon} alt='map' />
						<h3>{infoFooter?.[`city_${lang}`]}</h3>
						<h4>{infoFooter?.[`address_${lang}`]}</h4>
					</div>
					<div className='headerTopItems'>
						<img className='headerTopItemsIcons' src={phoneIcon} alt='phone' />
						<h3>{infoFooter?.phoneNumber_RU}</h3>
					</div>
					<div className='headerTopItems'>
						<Link className='HWITF' to={"https://api.whatsapp.com/send?phone=" + infoFooter?.whatsApp_RU}>
							<img src={whatsappIcon} alt="wha" />
						</Link>
						<Link className='HWITF' to={"https://t.me/" + infoFooter?.telegram}>
							<img src={telegramIcon} alt="telegram" />
						</Link>
						<NavLink className='HWITFF' to={'https://www.instagram.com/' + infoFooter?.instagram_RU}> <img src={instaIcon} alt="insta" /></NavLink>
						<NavLink className='HWITFF' to={'https://www.facebook.com/' + infoFooter?.facebook}>    <img src={facebookIcon} alt="facebook" /></NavLink>
					</div>
				</div>
			</div>
			<div className='container'>
				<nav className='nav'>
					<Link to={'/'} className='HLogo'>
						<img src={Logo} alt="logo" />
					</Link>
					<div className='navTopItems'>
						<Link className='HWITF' to={"https://api.whatsapp.com/send?phone=" + infoFooter?.whatsApp_RU}>
							<img src={whatsappIcon} alt="wha" />
						</Link>
						<Link className='HWITF' to={"https://t.me/" + infoFooter?.telegram}>
							<img src={telegramIcon} alt="telegram" />
						</Link>
						<Link className='HWITFF' to={'https://www.instagram.com/' + infoFooter?.instagram_RU}>
							<img src={instaIcon} alt="Instagram" />
						</Link>
						<Link className='HWITFF' to={"https://t.me/" + infoFooter?.facebook}>    <img src={facebookIcon} alt="facebook" /></Link>
					</div>

					<div className='MobileSearchContainer'>

						<MobileSearch mobileSearch={mobileSearch} setMobileSearch={setMobileSearch} search={search} setSearch={setSearch} />
					</div>

					<ul className='nav_menu'>
						<li className="nav_item"><NavLink to={'/about-us'} className={({ isActive }) =>
							isActive ? 'nav_link active_nav' : 'nav_link'
						} >{t("header.about_us")}</NavLink></li>
						<li className="nav_item"><NavLink to={'/special-equipment'} className={({ isActive }) =>
							isActive ? 'nav_link active_nav' : 'nav_link'
						}>{t('header.special_equipment')}</NavLink></li>
						<li className="nav_item"><NavLink to={'/spare-parts'} className={({ isActive }) =>
							isActive ? 'nav_link active_nav' : 'nav_link'
						}>{t('header.spare_parts')}</NavLink></li>
						<li className="nav_item"><NavLink to={'/services'} className={({ isActive }) =>
							isActive ? 'nav_link active_nav' : 'nav_link'
						}>{t('header.services')}</NavLink></li>
						<li className="nav_item"><NavLink to={'/contact'} className={({ isActive }) =>
							isActive ? 'nav_link active_nav' : 'nav_link'
						}>{t('header.contact')}</NavLink></li>
					</ul>
					<div onClick={navToggle} className={isActiveBurger ? 'nav_toogler toogle' : 'nav_toogler'}>
						<div className="line1"></div>
						<div className="line2"></div>
						<div className="line3"></div>
					</div>
					{isActiveBurger && <BurgerMenu isActiveBurger={isActiveBurger} setIsActiveBurger={setIsActiveBurger} setMobileSearch={setMobileSearch} />}

					<div className='navbarSearch'>
						<div className='Language' >
							<div className={i18n.language === 'RU' ? 'ru_active' : 'ru'} onClick={() => changeLanguage('RU')}>RU</div>
							<div className={i18n.language === 'CHS' ? 'ch_active' : 'ch'} onClick={() => changeLanguage('CHS')}>中文</div>
						</div>
						<div className='Searchcontainer'>
							<input value={search} onChange={(e) => setSearch(e.target.value)} onKeyPress={handleKeyPress} className='searchInput' type="search" placeholder={t('header.search')} />
							<img className='searchIcon' src={searchIcon} alt="" />
							<div className={search.trim().length >= 3 ? 'searchBox' : 'sr'}>

								{
									special?.length > 0 ?

										special?.slice(0, 3)?.map(el => (
											<Link onClick={() => setSearch('')} to={`/detail-equipment/${el.id}`} className='searchCard' key={el.id}>
												<img src={el.photo ? el.photo : greyBg} alt="logo" className='searchImg' />
												<div className='searchCardRigth'>
													<h2 className='searchName'>{lang === 'RU' ? el.name_RU?.length > 15 ? el.name_RU?.slice(0, 15) + '...' : el.name_RU : el.name_CHS?.length > 15 ? el.name_CHS?.slice(0, 15) + '...' : el.name_CHS}</h2>
													<h4 className='searchExistence'>{lang === 'RU' ? el?.existence_RU : el?.existence_CHS}</h4>
													<h3 className='searchPrice'>{el?.price}</h3>
												</div>
											</Link>
										))

										: <h2 className='searchNotFound'>{t('header.searchNotFound')}</h2>
								}
								<Link onClick={() => setSearch('')} to={`/special-equipment?search=${search}`}>
									<h3 className='Viewmore'>{t('header.viewmore')}</h3></Link>
							</div>
						</div>
					</div>
					{mobileSearch && <input className='search_input' onChange={(e) => setSearch(e.target.value)} type='search' placeholder={t('header.search')} />}
				</nav>
			</div>
		</header>
	);
};

export default Navbar;

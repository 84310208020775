import React, { FC, useEffect } from 'react'
import s from './Contact.module.scss'
import { FaLocationDot } from 'react-icons/fa6'
import { MdAccessTimeFilled } from 'react-icons/md'
import { FaPhoneAlt } from 'react-icons/fa'
import FormContact from '../FormContact/FormContact'
import { useAppSelector } from '../../store/hooks/hooks'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import conWhatsapp from '../../assets/Header_images/Vector4kwhatsapp.png'
import conTelegramm from '../../assets/Header_images/Vector4ktelegram.png'
import conInstagramm from '../../assets/Header_images/ri_instagram-fill4kinstagram.png'
import conFacebook from '../../assets/Header_images/ic_baseline-facebook4kFacebook.png'
import i18n from '../../i18n'
import { useTranslation } from 'react-i18next'


const Contact: FC = () => {
	const { infoFooter } = useAppSelector(state => state.special)
	const { t } = useTranslation()
	const lang = i18n.language

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className={s.container}>
			<Helmet>
				<link rel='canonical' href='http://imotors.kg/contact' />
				<title>{t('helmet.contact')}</title>
			</Helmet>
			<div className={s.wrapper}>
				<div className={s.content}>
					<div className={s.contact_side}>
						<h2>{t('contact.contact_side')}</h2>
						<div className={s.contact}>
							<div className={s.block_address}>
								<div className={s.block_text}>
									<FaLocationDot className={s.icons_address} />
									<p className={s.span_text}>{infoFooter?.[`city_${lang}`]}</p>
									<p className={s.usual_text}>{infoFooter?.[`address_${lang}`]}</p>
								</div>
								<div className={s.block_text}>
									<FaPhoneAlt className={s.icons_address} />
									<p className={s.span_text}>{infoFooter?.phoneNumber_RU}</p>


								</div >
								<div className={s.block_text}>
									<MdAccessTimeFilled className={s.icons_address} />
									<p className={s.span_text}>{t('contact.graphick')}</p>
									<p className={s.usual_text}>11:23-18:43</p>
								</div>
							</div >
							<div className={s.block_phone_number}>
								<Link to={"https://api.whatsapp.com/send?phone=" + infoFooter?.whatsApp_RU}>
									<img src={conWhatsapp} alt="logo" className={s.icons_network} />

								</Link>
								<Link to={'https://t.me/' + infoFooter?.telegram}>
									<img
										src={conTelegramm}
										alt='logo'
										className={s.icons_network}
									/>
								</Link>
								<Link to={'https://www.instagram.com/' + infoFooter?.instagram_RU}>
									<img src={conInstagramm} alt="logo" className={s.icons_networkk} />
								</Link>
								<Link to={'https://www.facebook.com/' + infoFooter?.facebook}>
									<img
										src={conFacebook}
										alt='log'
										className={s.icons_networkk}
									/>
								</Link>
							</div>
						</div >
					</div >
					<div className={s.contact_form}>
						<FormContact />
					</div>
				</div >
			</div >
		</div >
	)
}

export default Contact

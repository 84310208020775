import React, { FC } from 'react';
import './EquipmentPriceFilter.scss';
import { useTranslation } from 'react-i18next';
// import { fetchByPrice } from '../../../store/slice/specialEquipmentSlice';

interface EquipmentPriceFilterProps {
    price_min: string;
    price_max: string;
    // Not sure if this value prop is needed for price filter
    onChange: (key: string, value: string) => void;
}

const EquipmentPriceFilter: FC<EquipmentPriceFilterProps> = ({ onChange, price_max, price_min }) => {

    const { t } = useTranslation()

    return (
        <div className='Pricefilter'>
            <label className='PriceLabel'>
                {t('specialAcor.from')}
                <input
                    className='PriceInput'
                    type="text"
                    value={price_min}
                    onChange={(e) => onChange('price_min', e.target.value)}
                />
            </label>
            <label className='PriceLabel'>
                {t('specialAcor.before')}
                <input
                    className='PriceInput'
                    type="text"
                    value={price_max}
                    onChange={(e) => onChange('price_max', e.target.value)}
                />
            </label>
        </div>
    );
};

export default EquipmentPriceFilter;

import React, { FC } from 'react'
import b from './BgHome.module.scss'
import 'animate.css'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../store/hooks/hooks'
import { useTranslation } from 'react-i18next'

const BgHome: FC = () => {
	const { info } = useAppSelector(state => state.spareParts)
	const { mainSlider } = useAppSelector(state => state.special)
	const { t, i18n } = useTranslation()
	const lang = i18n.language

	return (
		<div className={b.BgHome}>
			<div className={b.container}>
				<div className={b.ExcavatorModels}>
					<div className={b.ExcavatorModelsAbout}>
						<h2 className={b.ExcavatorModelsTitle}>
							{info?.[`mainPageTitle_${lang}`]}
						</h2>
						<p className={b.ExcavatorModelsDescr}>
							{info?.[`mainPageDescription_${lang}`]}
						</p>
						<Link to={'/special-equipment'}>
							<button className={b.btnOrder}>{t('bgHome.View_catalog')}</button>
						</Link>
					</div>
				</div>
			</div>
			<div className={b.ExcavatorModelsImg}>
				{
					mainSlider?.mainSlider &&
					<img
						className='animate__animated animate__fadeInRightBig  '
						src={mainSlider?.mainSlider}
						alt='logo'
					/>
				}
			</div>

		</div>
	)
}

export default BgHome

import React, { FC } from 'react'
import b from './Advantages.module.scss'
import basketIcon from '../../../assets/Main_images/Vectorbasket.png'
import keyIcon from '../../../assets/Main_images/VectorkeyIcon.png'
import settingIcon from '../../../assets/Main_images/fa_gearssettingIcon.png'
import { useAppSelector } from '../../../store/hooks/hooks'
import { useTranslation } from 'react-i18next'


const Advantages: FC = () => {
	const { info } = useAppSelector(state => state.spareParts)
	const { t, i18n } = useTranslation()
	const lang = i18n.language


	return (
		<div className={b.container}>

			<div className={b.Advantages}>
				<h2 className={b.AdvantagesTitle}>{t("advantages.title")}</h2>
				<div className={b.AdvantagesBox}>
					<div className={b.AdvantagesCard}>
						<div className={b.AdvantagesCardItem}>
							<h2 className={b.AdvantagesCardTitle}>{t('advantages.Convenience')}</h2>
							<img src={basketIcon} alt='icon' />
						</div>
						<p className={b.AdvantagesCardParagraf}>{info?.[`advantagesOne_${lang}`]}</p>
					</div>
					<div className={b.AdvantagesCard}>
						<div className={b.AdvantagesCardItem}>
							<h2 className={b.AdvantagesCardTitle}>{t('advantages.Range_services')}</h2>
							<img src={keyIcon} alt='key' />
						</div>
						<p className={b.AdvantagesCardParagraf}>{info?.[`advantagesTwo_${lang}`]}</p>
					</div>
					<div className={b.AdvantagesCard}>
						<div className={b.AdvantagesCardItem}>
							<h2 className={b.AdvantagesCardTitle}>{t('advantages.Quality')}</h2>
							<img src={settingIcon} alt='setting' />
						</div>
						<p className={b.AdvantagesCardParagraf}>
							{info?.[`advantagesThree_${lang}`]}
						</p>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Advantages

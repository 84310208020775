import React, { useState, FC } from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import './AcorCop.scss'
import { fetchByAllAcordion } from '../../store/slice/specialEquipmentSlice'
import { Filters } from '../../store/modules'
import { useAppDispatch } from '../../store/hooks/hooks'
import EquipmentTypeFilter from '../SpecialEquipmentFilter/EquipmentTypeFilter/EquipmentTypeFilter'
import EquipmentBrandFilter from '../SpecialEquipmentFilter/EquipmentBrandFilter/EquipmentBrandFilter'
import EquipmentPriceFilter from '../SpecialEquipmentFilter/EquipmentPriceFilter/EquipmentPriceFilter'
import EquipmentYearFilter from '../SpecialEquipmentFilter/EquipmentYearFilter/EquipmentYearFilter'
import EquipmentExistenceFilter from '../SpecialEquipmentFilter/EquipmentExistenceFilter/EquipmentExistenceFilter'
import { useTranslation } from 'react-i18next'


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(255, 255, 255, .03)',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper': {
        transition: 'transform 0.3s',
        transform: 'rotate(90deg)', // Поворот иконки при закрытии
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(270deg)', // Поворот иконки при открытии
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}))


const Acor: FC = () => {
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const { t } = useTranslation()

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    const dispatch = useAppDispatch()
    const [filters, setFilters] = useState<Filters>({
        type: [],
        brand: [],
        price_min: '',
        price_max: '',
        year_min: '',
        year_max: '',
        existence: ''
    });

    const handleValueChange = (key: string, value: string) => {
        setFilters({ ...filters, [key]: value });
    };


    const filterResults = () => {
        dispatch(fetchByAllAcordion(filters))
    };

    return (
        <div>

            {/* Accordion 1 */}
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
                    <h2>{t('specialAcor.type')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <EquipmentTypeFilter filters={filters} setFilters={setFilters} />

                </AccordionDetails>
            </Accordion>
            {/* ==== */}
            <Accordion
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
            >
                <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                    <h2>{t('specialAcor.brand')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <EquipmentBrandFilter filters={filters} setFilters={setFilters} />
                </AccordionDetails>
            </Accordion>

            {/* Accordion 2 */}
            <Accordion
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
            >
                <AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
                    <h2>{t('specialAcor.price')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <EquipmentPriceFilter price_min={filters.price_min} price_max={filters.price_max} onChange={handleValueChange} />
                </AccordionDetails>
            </Accordion>

            {/* Accordion 3 */}
            <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
            >
                <AccordionSummary aria-controls='panel4d-content' id='panel4d-header'>
                    <h2>{t('specialAcor.year')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <EquipmentYearFilter year_min={filters.year_min} year_max={filters.year_max} onChange={handleValueChange} />
                </AccordionDetails>
            </Accordion>

            {/* Accordion 4 */}
            <Accordion
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
            >
                <AccordionSummary aria-controls='panel5d-content' id='panel5d-header'>
                    <h2>{t('specialAcor.existense')}</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <EquipmentExistenceFilter value={filters.existence} onChange={handleValueChange} />

                </AccordionDetails>
            </Accordion>

            <div className='findBtnBox'>
                <button className='frindBtn' onClick={filterResults}>{t('specialAcor.btnFind')}</button>
            </div>
        </div>
    )
}

export default Acor;

import React, { FC, useState } from 'react';
import './EquipmentExistenceFilter.scss';
import { useTranslation } from 'react-i18next';
// import { fetchByExistense } from '../../../store/slice/specialEquipmentSlice';

interface EquipmentExistenceFilterProps {
    value: string;
    onChange: (key: string, value: string) => void;
}

const EquipmentExistenceFilter: FC<EquipmentExistenceFilterProps> = ({ onChange, value }) => {
    const [isChecked, setIsChecked] = useState(false);
    const { t } = useTranslation()

    const handleChange = () => {
        setIsChecked(!isChecked);
        onChange('existence', isChecked ? '' : 'В наличии');
    };


    return (
        <div>
            <input
                className='ExistenInput'
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
            />
            <label className='ExistenLabel'>{t('specialAcor.In_stock')}</label><br />

            <input
                className='ExistenInput'
                type="checkbox"
                checked={!isChecked && value === 'Нет в наличии'}
                onChange={() => onChange('existence', 'Нет в наличии')}
            />
            <label className='ExistenLabel'>{t('specialAcor.not_available')}</label>
        </div>
    );
};

export default EquipmentExistenceFilter;
